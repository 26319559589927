import React, { ReactElement } from "react";

import { Stack } from "@envato/eds";

import MainContent, { Column, ContentSize } from "../../components/MainContent";
import Topbar, { TopbarProps } from "../../components/Topbar";
import Footer from "../../components/Footer";
import SidePanelColumn from "../../components/SidePanelColumn";

interface LayoutProps extends TopbarProps {
  size: ContentSize;
  sidePanel: ReactElement;
}

const Layout: React.FC<LayoutProps> = ({
  size,
  showSignIn,
  showSignUp,
  sidePanel,
  children,
}) => {
  return (
    <Stack>
      <Topbar showSignIn={showSignIn} showSignUp={showSignUp} />
      <MainContent size={size}>
        <SidePanelColumn>{sidePanel}</SidePanelColumn>
        <Column>{children}</Column>
      </MainContent>
      <Footer />
    </Stack>
  );
};

export default Layout;
